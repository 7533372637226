
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.search {
  margin-bottom: 8px;
}

.buttons {
  display: flex;
  margin-top: 8px;
}
