
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.message {
  position: relative;
  max-width: 95%;
  margin: 4px 16px 0 auto;
  display: flex;

  &.incoming {
    margin-left: 0;
  }

  &.incoming + &:not(.incoming),
  &:not(.incoming) + &.incoming {
    margin-top: 16px;
  }

  @include isTouch() {
    -webkit-user-select: none !important;
  }
}

.infoBlock {
  padding: 5px 16px;
  display: flex;
  flex-flow: column;
  font-size: calc(var(--font-scale) * 16px);
  line-height: 1.5;
  color: $message-text;
  border-radius: 16px 16px 0 16px;
  border: 1px solid $main-color;
  background-color: #fff;
  overflow: hidden;

  .incoming & {
    background-color: $bg-second;
    border: none;
    border-radius: 16px 16px 16px 0;
  }
}

.fromBlock {
  margin: 0 0 4px;
  font-size: calc(var(--font-scale) * 10px);
  line-height: 1.33;
  color: $text-secondary;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleNews {
  margin: 0 0 4px;
  font-size: calc(var(--font-scale) * 12px);
  font-weight: 600;
  line-height: 1.33;
  color: $text-secondary;
  max-width: 500px;
}

.avatar {
  margin-right: 8px;
  align-self: flex-end;
  flex: 0 0 auto;
}

.forwardedFrom {
  margin: 0;
  font-size: calc(var(--font-scale) * 12px);
  line-height: 1.33;
  font-weight: 700;
  color: $text-secondary;
}

.tick {
  font-size: calc(var(--font-scale) * 14px);
}

.contextMenuBtn {
  align-self: center;
  font-size: calc(var(--font-scale) * 22px);
  order: -1;

  &.incoming {
    margin-left: 8px;
    order: 0;
  }
}
