
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.contactSelect {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.context {
  margin-left: 16px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.name {
  display: flex;
  align-items: center;
  font-size: calc(var(--font-scale) * 14px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tick {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 7px;
  left: 30px;
  color: #fff;
  border-radius: 50%;
  background: #1ec81a;
}

.groupIcon {
  margin-right: 4px;
}

.teamIcon {
  margin-right: 4px;
}
