$main-color: #2375f6;
$bg-primary: #ebf0f4;
$bg-second: #f2f5f7;
$bg-third: #e2eaf0;
$connect-color: #e34234;

$message-text: #252d3c;
$text-primary: #2c3653;
$text-secondary: #505d77;
$text-tertiary: #7b91b7;

$text-placeholder: #9CACC9;

$chat-area-border: 2px solid $bg-primary;

$input-border: #cdd5e4;

$warning-color: #ff3f3d;

$link-color: #3D76C2;

$theme-users: (
  #2375F6,
  #1955B5,
  #103775,
  #071936,
  #1F67DB,
);

:root {
  --font-scale: 1;
  --main-color: #{$main-color};
}
