
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.box {
  position: relative;
  padding: 36px 16px 16px;
  background: $bg-second;
  border-radius: 8px;
  cursor: pointer;

  @include isMouse() {
    &:hover {
      box-shadow: 2px 2px 8px 2px rgba(#000, .5);
    }
  }
}

.statusesBox {
  position: absolute;
  top: 8px;
  left: 8px;
}

.title {
  margin: 0 0 16px;
  font-size: calc(var(--font-scale) * 16px);
  font-weight: 700;
}

.user {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.avatar {
  margin-right: 8px;
}

.userInfo {
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.date,
.userName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.date {
  font-size: calc(var(--font-scale) * 12px);
  color: $text-tertiary;
}

.newReply {
  margin-left: 8px;
  font-size: calc(var(--font-scale) * 14px);
  font-weight: 700;
  color: #1ec81a;
}
