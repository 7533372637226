
          @import "src/styles/_vars.scss";
          @import "src/styles/_mixins.scss";
.buttons {
  margin-top: 24px;
  display: flex;

  > button:nth-child(n+2) {
    margin-left: 16px;
  }
}

.search {
  margin-bottom: 8px;
}

.inputBox {
  display: flex;
}

.input {
  flex: 1;
}

.iconBtn {
  min-width: 40px;
  min-height: 40px;
  font-size: calc(var(--font-scale) * 22px);
  color: $text-tertiary;
}

.fileBox {
  padding: 0;
  max-height: 200px;
  overflow: auto;
  list-style: none;
}

.fileItem {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
